import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaginateResponse } from '@app/core/models/paginate-response.model';
import { ResponseModel } from '@app/core/models/response.model';
import { TruckResponse } from '@app/core/models/truck.model';
import { User } from '@app/core/models/user.model';
import { environment } from '@env/environment';

import { switchMap, of, Observable } from 'rxjs';

@Injectable()
export class TruckService {
	private URL: string = environment.apiUrl + 'trucks/';

	/**
	 * Constructor
	 */
	constructor(@Inject(HttpClient) private http: HttpClient) {
		// 
	}

	/**
	 * Get truck by id.
	 * @param truckId Truck Id identificator.
	 * @returns {Observable<TruckResponse>}
	 */
	getTruckById(truckId: number): Observable<TruckResponse> {
		const api = this.URL;
		return this.http.get(`${api}get-truck?truckId=${truckId}`).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<TruckResponse>;
				return of(res.data);
			}),
		);
	}

	getTrucks(paginate: { search: string; page: number; size: number }) {
		const api = this.URL;
		const { search, page, size } = paginate;
		

		return this.http
			.get(`${api}get-trucks?page=${page}&size=${size}&search=${search}`)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<
						PaginateResponse<TruckResponse>
					>;
					return of(res.data);
				}),
			);
	}

	deleteTruck(truckId: number) {
		const api = this.URL;
		return this.http.post(`${api}delete-truck`, { truckId: truckId }).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	addTruck(addTruck: any) {
		const api = this.URL;
		return this.http.post(`${api}add-truck`, addTruck).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	updateTruck(updateTruck: any) {
		const api = this.URL;
		return this.http.post(`${api}update-truck`, updateTruck).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}
}
